.flip-box {
    perspective: 1000px; /* Adding perspective for 3D effect */
    
  }
  
  .flip-box-inner {
    height: 200px;
    transition: transform 0.8s; /* Adding transition for smooth flip */
    transform-style: preserve-3d; /* Preserving 3D transformations */
  }
  
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg); /* Flipping on hover */
    
   
  }
  
  .flip-box-front,
  .flip-box-back {
   
    height: 100%;
    position: absolute;
    backface-visibility: hidden; /* Hiding backface */
    border-radius: 4px;
  }
  
  .flip-box-front {
    width:95%;
    background-color: #b32400; /* Front side color */
    
  }
  
  .flip-box-back {
    width:95%;
    background-color: #00b386; /* Back side color */
    transform: rotateY(180deg); /* Initially hidden */
  }
  
 